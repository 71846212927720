<template>
	<div>
		<div class="queryBox mb20 ">
			<input  type="file" accept="image/*" multiple="multiple" id="uploadImg" @change="uploadImg($event)">
			<input  type="file" accept="image/*" id="uploadImgOnely" @change="uploadImgOnely($event)">
			<el-button class="mr20" slot="trigger" size="mini" type="primary" @click="chooseFile">批量选择文件</el-button>
			<el-button class="mr20" style="margin-left: 10px;" size="mini" type="success" @click="submitUpload">上传到服务器</el-button>
			<span class="mr20">
				<span>批量备注：</span>
				<el-input  size="mini" style="width:200px;" placeholder="请输入备注" v-model="noteAll" clearable></el-input>
			</span>			
			<el-button class="mr20" style="margin-left: 10px;" size="mini" type="success" @click="setNote()">批量备注</el-button>
		</div>
		
		<div v-for="(item,index) in fileList">
			<div class="fileItem  mb10">
				<div class="error" v-if="item.error">{{item.error}}</div>
				<div  class="flexX flexBetween flexcenter">
					<div class="flexX  flexcenter flex1">
						<el-image class="mr20" style="width: 80px; height: 80px":src="item.path" :preview-src-list="[item.path]"></el-image>
						<span>{{item.name}}</span>
					</div>
				
					<div class="flex1">
						<span>备注：</span><el-input  size="mini" style="width: 400px;" placeholder="请输入备注" v-model="item.note" clearable></el-input>
					</div>
					<div>
						<el-link type="primary"  class="mr20" @click="edit(item,index)">编辑</el-link>
						<el-link type="danger"  class="mr20" @click = "deleteImg(item,index)">删除</el-link>
					</div>
				</div>
			</div>			  
		</div>
		<div class="popup" :class="{'show':loading}">
			<div class="fs12" style="color: #fff;">正在上传第{{imgIndex}}张图片，请稍后......</div>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
		import {
			isEmpty,
			isPositiveNumber,
			isMoney,
			isMobile
		} from '@/util/validate'
		import {
			Message
		} from 'element-ui'
		import QRCode from 'qrcodejs2'
		export default {
			data() {
				return {					
					loading: false,
					disLoading: false,
					id: '',
					fileList:[],
					imgIndex:0,
					noteAll:'',
					multiple:true,
					item:{},
					index:"",
				};
			},
			created() {
				if (this.$route.query.id) {
					this.id = this.$route.query.id;
				}
			
			},
			filters: {
				tofixed: function(value) {
					if (value || value == 0) {
						// console.log('sssssssssss', (value * 1).toFixed(2) + '')
						// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
						let val = Math.floor(((value * 1) * 100).toPrecision(12))
						return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					}
				}
			},
			methods: {
				edit(item,index){
					this.item =item
					this.index =  index
					this.multiple = false
					let uploadImg = window.document.getElementById("uploadImgOnely")
					uploadImg.click()
				},
				chooseFile(){
					this.multiple = true
					let uploadImg = window.document.getElementById("uploadImg")
					uploadImg.click()
				},
				uploadImg(e){
					let fileList = e.srcElement.files
					let imgSrc=[]
					if(fileList.length > 0){
						for(let i = 0; i < fileList.length; i++) {
							let imgSrcI = this.getObjectURL(fileList[i]);
							imgSrc.push({"path":imgSrcI,"file":fileList[i],"name":fileList[i].name,"note":this.noteAll?this.noteAll:'',"error":""});
						}
						this.fileList = imgSrc
					}
					
					console.log("===+++===",fileList)
				},
				uploadImgOnely(e){
					let fileList = e.srcElement.files
					let imgSrc=[]
					if(fileList.length > 0){
						let imgSrcI = this.getObjectURL(fileList[0]);
						this.fileList[this.index] = {"path":imgSrcI,"file":fileList[0],"name":fileList[0].name,"note":this.noteAll?this.noteAll:'',"error":""}
						this.$forceUpdate()
					}					
				},
				deleteImg(item,index){
					this.fileList.splice(index,1)
				},
				getObjectURL(file) {
					let url = null ;
					if (window.createObjectURL!=undefined) { // basic
						url = window.createObjectURL(file) ;
					} else if (window.URL!=undefined) { // mozilla(firefox)
						url = window.URL.createObjectURL(file) ;
					} else if (window.webkitURL!=undefined) { // webkit or chrome
						url = window.webkitURL.createObjectURL(file) ;
					}
					return url ;
				},
				setNote(){
					this.fileList.forEach(item=>{
						item.note = this.noteAll
					})
				},
				submitUpload(){
					if(this.fileList.length > 0){
						this.uploadStoreQrAndAdd(0)
					}
					else{
						this.$message({
							type: 'error',
							message: `请选择二维码`
						})
					}
				},
				uploadStoreQrAndAdd(index){
					let username=local.get('bpcusername')
					let tid=local.get('bpctid')
					let store_id=this.id
					let login_type=2
					let formData = new FormData();
					let item = this.fileList[index]
					
					formData.append('image',item.file) //遍历添加数据
					formData.append('username',username) //遍历添加数据
					formData.append('tid',tid) //遍历添加数据
					formData.append('store_id',store_id) //遍历添加数据
					formData.append('login_type',login_type) //遍历添加数据
					formData.append('note',item.note) //遍历添加数据
					this.loading = true
					this.imgIndex = index + 1
					 this.$api.uploadStoreQrAndAdd(formData).then(res=>{
						 this.loading = false
						 if(res.status == 1){
							 this.$message({
							 	type: 'success',
							 	message: `第${index+1}张图片上传成功`
							 })
							 this.fileList.splice(index,1)
							 if(index<this.fileList.length-1){
							 	this.uploadStoreQrAndAdd(index)
							 }else{
							 	// self.loadingEQ = false
							 }
						 }else{
							 this.fileList[index].error = res.msg
							 this.uploadStoreQrAndAdd(index+1)
						 }
					 }).catch(error=>{
						  this.loading = false
					 })
				},
			
			},
			watch: {
			},
	
		};
	
</script>

<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';
	#uploadImg,#uploadImgOnely{
		display: none;
	}
	.fileItem{
		padding: 10px;
		border: solid 1px $tableBorderColor;
		.error{
			padding-left: 100px;
			color: $danger;
			font-size: 12px;
		}
	}
	.popup {
		position: fixed;
		left: 0;
		top: 0;
		display: none;
		background: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		z-index: 9999;
	
		&.show {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	
	}
</style>
